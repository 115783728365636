<template>
  <div
    class="md:tw-pl-8"
    @dragover.prevent
    @drop.prevent="onDrop($event, { drop: 'blank', newInvoiceContact: true })"
  >
    <div class="tw-flex tw-flex-row tw-flex-wrap tw-justify-between tw-gap-4 tw-items-center">
      <label
        title="Versleep naar rechts de contacten naar waar de factuur moet worden verzonden. Meerdere contacten kunnen samengevoegd worden onder 1 factuuradres. Geef het aandeel van de kostverdeling mee (%) hoe de kost zal worden verdeeld indien er meerdere facturen worden opgemaakt."
        class="formulate-label"
      >
        Facturatiecontacten
        <i class="far fa-info-circle" />
      </label>

      <FormulateInput
        v-show="(contacts.length > 1) && invoiceContacts.length"
        v-model="disclaimer"
        :id="`invoice_recipient_groupings_${contactRole}_disclaimer_toggle`"
        type="toggle"
        name="disclaimer"
        label="Splits de facturatiecontacten"
        ignored
        outer-class="tw-my-0"
        @input="disclaimerToggled"
      />
    </div>

    <!-- We don't want to use the same name field, because if this section is used multiple times on the same page, it can cause data conflict. -->
    <FormulateInput
      v-model="invoiceContacts"
      type="group"
      :name="`invoice_recipient_groupings_${contactRole}`"
      :repeatable="true"
      remove-position="after"
      validation="validInvoicingShares"
      error-behavior="live"
      group-repeatable-class="tw-relative"
      outer-class="tw-m-0"
    >
      <template #default="{ model, index }">
        <div
          class="tw-my-6 tw-p-2 tw-rounded tw-border"
          @dragover.prevent
          @drop.prevent.stop="onDrop($event, {
            drop: `invoiceContact${index}`,
            contacts: model[index] && model[index].contacts,
            has_invoices: model[index] && model[index].has_invoices
          })"
        >
          <select
            v-if="model[index]"
            id="prefix"
            type="select"
            name="prefix"
            class="tw-px-1 tw-absolute tw--translate-y-full tw-bg-white tw-font-bold"
            @input="addPrefix($event, model[index])"
          >
            <option value="">Titel selecteren</option>
            <option
              v-for="prefix in prefixTitles"
              :key="prefix.id"
              :value="getPrefixLabel(prefix, model[index])"
            >
              {{ getPrefixLabel(prefix, model[index]) }}
            </option>
          </select>

          <FormulateInput
            type="text"
            name="title"
            placeholder="Titel"
            :element-class="['tw-flex tw-items-center !tw-text-sm']"
            :input-class="[
              'tw-text-sm tw-h-8 tw-mt-0.5 disabled:tw-bg-white',
              {
                'tw-rounded-r-none tw-border-r-0':
                  (model.length > 1) || (model[index] && model[index].invoicing_share < 100)
              }
            ]"
            outer-class="tw-my-2"
          >
            <template #suffix>
              <FormulateInput
                v-if="model[index]"
                v-show="(model.length > 1) || (model[index].invoicing_share < 100)"
                v-model="model[index].invoicing_share"
                type="number"
                name="invoicing_share"
                step="0.01"
                min="0"
                :element-class="['tw-flex tw-items-center']"
                :input-class="['tw-text-sm tw-rounded-none tw-h-8 hide-number-input-arrows tw-px-0 tw-text-center disabled:tw-bg-white']"
                outer-class="tw-mt-0.5 tw-max-w-[80px]"
              >
                <div slot="suffix" class="input-unit tw-h-8 tw-m-0">
                  <i class="far fa-percent" />
                </div>
              </FormulateInput>
            </template>
          </FormulateInput>
          <div v-if="model[index]" class="tw-flex tw-flex-wrap tw-gap-2 tw-pr-4">
            <router-link
              v-for="({ contact, primary }, contactIndex) in model[index].contacts"
              :key="contact.id"
              :to="{ name: 'ContactDetails', params: { id: contact.id } }"
              target="_blank"
              :draggable="!model[index].has_invoices"
              :class="[
                'tag tw-block',
                disclaimerAccepted ? 'tw-cursor-move' : 'tw-cursor-pointer',
                primary ? 'tw-bg-success !tw-text-white' : 'tw-border tw-border-success !tw-text-success'
              ]"
              @dragover.native="onDragOver"
              @dragstart.native="onDragStart($event, {
                primary,
                contact,
                index: contactIndex,
                invoiceIndex: index,
                source: `invoiceContact${index}`
              })"
            >
              {{ contact.display_name }}
            </router-link>
          </div>
          <!-- For now: hide the checkbox -->
          <FormulateInput
            v-if="model[index] && showShouldReceiveInvoiceAutomaticallyCheckbox"
            name="should_receive_invoice_automatically"
            type="checkbox"
            label="Factuur automatisch per e-mail versturen"
            :label-class="['tw-text-xs tw-mb-0.5']"
          />
        </div>
      </template>

      <template #addmore>
        <div v-if="invoiceContacts.length === 0 && contacts.length" class="tw-my-4">
          <button
            type="button"
            title="Facturatiecontact toevoegen"
            class="tw-font-semibold link tw-inline-block"
            @click="addDefaultInvoiceContact(false)"
          >
            + Facturatiecontact toevoegen
          </button>
          <span>
            of versleep een contact naar rechts voor het aanmaken van facturatiecontact.
          </span>
        </div>
        <div v-else />
      </template>

      <template #remove="{ model, index, removeItem }">
        <!-- If disclaimer not accepted, disable removing -->
        <button
          v-if="model[index]"
          type="button"
          title="Verwijderen"
          :disabled="model[index].has_invoices"
          class="
            tw-px-1.5 tw-py-0.5 tw-border
            tw-absolute tw-right-0 tw-bottom-0 tw-translate-x-1/2 tw-translate-y-1/2
            tw-text-danger tw-border-danger tw-rounded-full tw-shadow-card tw-bg-white
            hover:tw-bg-danger hover:tw-text-white
            disabled:tw-bg-gray-cc disabled:tw-text-white disabled:tw-border-gray-cc disabled:tw-cursor-not-allowed
          "
          @click="removeItem(index)"
        >
          <i class="fas fa-trash" />
        </button>
      </template>
    </FormulateInput>

    <details :open="showDisclaimer" class="tw-mt-16">
      <summary class="tw-font-bold">Disclaimer</summary>
      <div class="tw-mt-4 tw-italic">
        <p>
          Standaard zijn de facturatiecontacten idem aan de {{ roleDutchPlural }}: in onze opdrachten worden de {{ roleDutchPlural }} immers hoofdelijk en ondeelbaar verbonden voor de verbintenissen die voortvloeien uit onze opdracht. Dit betekent dat Dewaele hier ook moet naar handelen en dus ook moet factureren aan alle {{ roleDutchPlural }}. Zoniet, ondermijnen we onze eigen opdracht! Zorg er dan ook voor dat je in het vrij veld van de aanspreking alle namen van {{ roleDutchPlural }} benoemt. Dit zal de aanspreking op de uiteindelijke factuur zijn.
        </p>

        <p class="tw-text-green-600">
          GOED: Jan Tanghe + Julie Vanaelst = Dhr en Mevr Tanghe - Vanaelst OF Familie Tanghe - Vanaelst
        </p>
        <p class="tw-text-red-600">
          FOUT: Jan Tanghe + Julie Vanaelst = Dhr Tanghe
        </p>

        Voor een uitzondering, klik op "splits facturatiecontacten" en lees aandachtig de disclaimer.
      </div>
    </details>

    <BaseModal ref="disclaimerModal" title="Disclaimer" @hide="disclaimer = disclaimerAccepted">
      <div class="tw-text-danger">
        <b>OPGELET</b>: de regel is dat de facturatie voor 100% gebeurt op <u>alle {{ roleDutchPlural }} samen</u>.
        Je mag de facturen niet verdelen onder de {{ roleDutchPlural }}, want in onze verkoop- of verhuuropdrachten staat er dat alle verbintenissen die eruit voortvloeien <u>hoofdelijk</u> en <u>ondeelbaar</u> zijn tegenover de partijen, hun erfgenamen of rechthebbenden, uit welke hoofde dan ook.
        <b>Het negeren van deze regel kan leiden tot wanbetaling met procedurele invorderingskosten tot gevolg.</b>
        Bij twijfel, contacteer legal.
      </div>

      <button
        type="button"
        class="tw-mt-6 formulate-button tw-bg-warning tw-w-full"
        @click="acceptDisclaimer"
      >
        Gelezen en goedgekeurd
      </button>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { warningModal } from '@/modalMessages'

import { getInvoiceContactPrefixTitles } from '@/services/apiService'
import { getProjectInvoiceContacts, updateProjectInvoiceContacts } from '@/services/projects'
import { getPropertyInvoiceContacts, updatePropertyInvoiceContacts } from '@/services/properties'

export default {
  name: 'EntityInvoiceContacts',
  props: {
    formValues: {
      type: Object,
      required: true
    },
    projectId: {
      type: [String, Number]
    },
    propertyId: {
      type: [String, Number]
    },
    contacts: {
      type: Array
    },
    contactRole: {
      type: String
    },
    showDisclaimer: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      invoiceContacts: [],
      disclaimer: false,
      disclaimerAccepted: false, // We need two booleans to change the state on hiding the modal (not accepting/accepting)
      sourceElement: null,
      prefixTitles: []
    }
  },
  computed: {
    ...mapGetters('properties', ['getPropertyById']),

    property () {
      if (this.$route.meta.entity_type !== 'property') return null
      return this.getPropertyById(this.propertyId)
    },
    roleDutchPlural () {
      const roleMap = {
        owner: 'eigenaars',
        buyer: 'kopers',
        renter: 'huurders'
      }
      return roleMap[this.contactRole]
    },
    roleId () {
      const roleMap = {
        owner: 1,
        buyer: 2,
        renter: 3
      }
      return roleMap[this.contactRole]
    },
    showShouldReceiveInvoiceAutomaticallyCheckbox () {
      // DEW-9808: It should only be visible for properties with transaction type beheer.
      const transactionTypeRentalManagement = 5
      return this.property?.transaction_type === transactionTypeRentalManagement
    }
  },
  created () {
    this.init()
  },
  methods: {
    getPrefixLabel (option, invoiceContact) {
      const primaryContact = invoiceContact.contacts.find(contact => contact.primary)?.contact
      const lang = primaryContact?.language || 'nl'
      return option[`title_${lang}`]
    },
    addPrefix (event, invoiceContact) {
      const value = event.target.value
      if (!value) return

      this.$set(invoiceContact, 'title', value + ' ' + invoiceContact.title)
      return value
    },

    isBefore (el1, el2) {
      if (!el1 || !el2) return
      if (el1.parentNode === el2.parentNode) {
        // nodeType should not be a document (9)
        for (let cur = el1.previousSibling; cur && cur.nodeType !== 9; cur = cur.previousSibling) {
          if (cur === el2) return true
        }
      }
      return false
    },
    onDragStart (event, { index, source, invoiceIndex, contact, primary }) {
      this.sourceElement = event.target // To reference when moving items around other items.
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'

      // To make sure sorting in drag and drop works as expected in Firefox
      event.dataTransfer.setData('text/plain', null)

      event.dataTransfer.setData('index', index)
      event.dataTransfer.setData('source', source)
      event.dataTransfer.setData('primary', primary)
      event.dataTransfer.setData('invoiceIndex', invoiceIndex)
      event.dataTransfer.setData('contact', JSON.stringify(contact))
    },
    onDragOver (event) {
      // If the sourceElement (the one we picked up to drag), is before the target, then just insert it before target.
      // If not, then insert it between the target's and the next element
      if (this.isBefore(this.sourceElement, event.target)) {
        event.target.parentNode.insertBefore(this.sourceElement, event.target)
      } else {
        event.target.parentNode.insertBefore(this.sourceElement, event.target.nextSibling)
      }
    },
    onDrop (event, payload = {}) {
      const { drop, newInvoiceContact = false, contacts, has_invoices } = payload
      const index = event.dataTransfer.getData('index')
      const source = event.dataTransfer.getData('source')
      const primary = event.dataTransfer.getData('primary')
      const invoiceIndex = event.dataTransfer.getData('invoiceIndex')
      const contact = JSON.parse(event.dataTransfer.getData('contact'))

      if ((drop === source) && source.includes('invoiceContact')) {
        // If moving contacts within the same invoice contact
        const previousSibling = this.sourceElement.previousSibling
        if (previousSibling) return // The element wasn't moved to the first place, do nothing.

        const primaryContact = contacts.find(contact => contact.primary)
        this.$set(primaryContact, 'primary', false)

        const targetContact = contacts[index]
        this.$set(targetContact, 'primary', true)
        // We need to make sure the element is moved to the first position in the array too
        // so that when we set the first contact to primary on split, we don't have two contacts as primary.
        contacts.splice(index, 1)
        contacts.unshift(targetContact)

        return event.preventDefault()
      } else if (drop === source) return // When lifted and dropped at the same place, that is not an invoice contact.

      // If the invoice contact has invoices, then don't allow further operations.
      if (has_invoices) return event.preventDefault()

      if ((drop === 'blank') && (this.invoiceContacts.length > 0) && !this.disclaimerAccepted) {
        // When dropping in the blank area to create an invoice contact, check if there is an invoice contact already
        // If there is no invoice contact, we allow creating the first one without accepting the disclaimer.
        // If there is an invoice contact, the user needs to accept the disclaimer to create another invoice contact.

        // this.disclaimer = true
        return event.preventDefault()
      }

      if (contacts) {
        // Don't add the same contact twice to an invoice contact.
        const contactAlreadyExists = contacts.find(el => el.contact.id === contact.id)
        if (contactAlreadyExists) return
        else contacts.push({ contact, primary: false })
      }

      // If moving a contact from one invoiceContact to another or outside, remove it from the origin.
      const sourceInvoiceContact = this.invoiceContacts[invoiceIndex]
      if (sourceInvoiceContact) sourceInvoiceContact.contacts.splice(index, 1)
      if (primary && sourceInvoiceContact.contacts.length) this.$set(sourceInvoiceContact.contacts[0], 'primary', true)

      if (newInvoiceContact) {
        // If the contact is dropped at an empty area and we need to create a new one.
        const invoiceContact = {
          title: contact.display_name,
          invoicing_share: this.invoiceContacts.length === 0 ? 100 : 0,
          contacts: [
            {
              contact,
              primary: true
            }
          ]
        }

        // Due to the deeply nested partial form inputs, v-model doesn't update the form values properly.
        // Leading to the issue described in https://dewaele.atlassian.net/browse/DEW-9849
        // Passing the formValues to this component and updating the guessed field value fixes the issue.
        if (this.invoiceContacts.length) this.invoiceContacts.push(invoiceContact)
        else this.$set(this.formValues, `invoice_recipient_groupings_${this.contactRole}`, [invoiceContact])
      }

      if (sourceInvoiceContact?.contacts.length === 0) {
        // Wait for the DOM to update, to avoid duplicate id issue, as VueFormulate needs to re-evaluate keys.
        // Once DOM updates, remove the empty invoiceContact from the list.
        this.$nextTick(() => {
          this.invoiceContacts.splice(invoiceIndex, 1)
        })
      }
    },
    removeContactFromInvoiceContacts (contactId) {
      const invoiceContacts = this.invoiceContacts
        .map(contact => contact.contacts)
        .flat()
        .map(contact => contact.contact.id)

      if (invoiceContacts.includes(contactId)) {
        // Contact is linked to an invoice contact.
        return warningModal('Kan contact niet verwijderen zolang hij is gelinkt aan een facturatiecontact')
      } else return true // Can remove the contact successfully
    },
    getContactDetails (contact) {
      const details = contact[this.contactRole]
      return details || contact
    },
    addDefaultInvoiceContact (automated = false) {
      if (automated && this.contactRole !== 'owner') return // Adding a single invoice contact automatically is only required in case of owners
      if (this.contacts.length && this.invoiceContacts.length) return

      // In case of newly added contacts, we just have the contact.
      const contacts = this.contacts.map((contact, index) => {
        return {
          contact: this.getContactDetails(contact),
          primary: index === 0
        }
      })
      const invoiceContact = {
        title: contacts[0]?.contact?.display_name || '',
        invoicing_share: 100,
        contacts
      }

      // Due to the deeply nested partial form inputs, v-model doesn't update the form values properly.
      // Leading to the issue described in https://dewaele.atlassian.net/browse/DEW-9849
      // Passing the formValues to this component and updating the guessed field value fixes the issue.
      this.$set(
        this.formValues,
        `invoice_recipient_groupings_${this.contactRole}`,
        [invoiceContact]
      )
    },

    disclaimerToggled (value) {
      this.disclaimerAccepted = false
      if (!value) return false

      return this.$refs.disclaimerModal.show()
    },
    acceptDisclaimer () {
      this.disclaimerAccepted = true
      this.$refs.disclaimerModal.hide()
    },

    async init () {
      return Promise.all([
        this.loadInvoiceContacts(),
        this.loadPrefixTitles()
      ])
    },
    async loadInvoiceContacts () {
      const response = this.propertyId
        ? await getPropertyInvoiceContacts(this.propertyId, { role: this.roleId })
        : await getProjectInvoiceContacts(this.projectId, { role: this.roleId })
      this.invoiceContacts = response.data
      return response
    },
    async loadPrefixTitles () {
      const response = await getInvoiceContactPrefixTitles()
      this.prefixTitles = response.data?.results
      return response
    },
    async saveInvoiceContacts () {
      // We cannot use the form values for some reason, as they just don't update.
      const invoice_recipient_groupings = this.invoiceContacts.map(({ contacts, ...invoiceContact }) => {
        return {
          contacts: contacts.map(({ primary, contact }) => {
            return {
              primary,
              contact_id: contact.id
            }
          }),
          ...invoiceContact
        }
      })
      const response = this.propertyId
        ? await updatePropertyInvoiceContacts(this.propertyId, { invoice_recipient_groupings, role: this.roleId })
        : await updateProjectInvoiceContacts(this.projectId, { invoice_recipient_groupings, role: this.roleId })
      return response
    }
  }
}
</script>
